import { Link } from 'gatsby';
import React from 'react';
import DoubleDownIcon from '../../images/Icons/links/double-down.svg';
import CrashManagementLogo from '../../images/Crash-Management-Logo.png';
import DingGoLogo from '../../images/DingGo-Logo.png';

const CrashManagementCta = ({ countryCode, crashManagementPage }) => (
  <section className="py-7 bg-light-blue modern-page">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="hero-split">
            <div className="hero-split-left">
              <h1 className="modern-h1 text-blue">End-to-End Digital Crash Management</h1>
              <p className="text-blue text-description">
                DingGo Crash Management is a market leading Crash Management provider powered by the DingGo Fleet
                technology platform, built to provide the best possible customer experience, reliability, and reporting.
              </p>
              <div className="button-link-combo">
                {crashManagementPage ? (
                  <Link to="/demo" className="btn btn-rectangular m-right-20">
                    Request a Demo
                  </Link>
                ) : (
                  <Link to="/crash-management" className="btn btn-rectangular m-right-20">
                    Find out more
                  </Link>
                )}
                {crashManagementPage && (
                  <a href="#productDescription" className="link">
                    or find out more below
                    <img src={DoubleDownIcon} alt="Find out more"/>
                  </a>
                )}
              </div>
            </div>
            <div className="hero-split-right">
              {countryCode === 'nz'
                && <img src={CrashManagementLogo} alt="Crash Management" className="crash-logo"/>}
              {countryCode === 'au' && <img src={DingGoLogo} alt="DingGo" className="crash-logo"/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default CrashManagementCta;
