import React, { useState } from 'react';
import DoubleRightIcon from '../../images/Icons/links/double-right.svg';

const Coverage = () => (
  <section className="py-7 bg-light-orange coverage-section">
    <div className="container">
      <div className="row justify-content-left">
        <div className="col-12">
          <h2 className="modern-h2 text-purple">Coverage</h2>
          <p className="text-description">Access one of Australia and New Zealand's largest independent repairer networks</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="divider-gradient divider-gradient-orange"/>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className="col-12">
          <h3 className="modern-h3 text-purple">Locations</h3>
          <div className="button-links">
            <a href="/car-body-shops/nsw" className="button-link">New South Wales</a>
            <a href="/car-body-shops/act" className="button-link">ACT</a>
            <a href="/car-body-shops/vic" className="button-link">Victoria</a>
            <a href="/car-body-shops/qld" className="button-link">Queensland</a>
            <a href="/car-body-shops/sa" className="button-link">South Australia</a>
            <a href="/car-body-shops/nt" className="button-link">Northern Territory</a>
            <a href="/car-body-shops/wa" className="button-link">Western Australia</a>
            <a href="/car-body-shops/tas" className="button-link">Tasmania</a>
          </div>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className="col-12">
          <a href="/car-body-shops" className="link">
            All locations
            <img src={DoubleRightIcon} alt="All locations"/>
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="divider-gradient divider-gradient-orange"/>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className="col-12">
          <h3 className="modern-h3 text-purple">Services</h3>
          <div className="button-links">
            <a href="/panel-beating-services/dent-removal" className="button-link">Dents</a>
            <a href="/panel-beating-services/hail-damaged-car" className="button-link">Hail</a>
            <a href="/panel-beating-services/car-window-repairs" className="button-link">Cracked Glass</a>
            <a href="/panel-beating-services/bumper-repairs" className="button-link">Bumpers</a>
          </div>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className="col-12">
          <a href="/panel-beating-services" className="link">
            All services
            <img src={DoubleRightIcon} alt="All services"/>
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="divider-gradient divider-gradient-orange"/>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className="col-12">
          <h3 className="modern-h3 text-purple">Car Make Specialist</h3>
          <div className="button-links">
            <a href="/auto-repairs/toyota" className="button-link">Toyota</a>
            <a href="/auto-repairs/nissan" className="button-link">Nissan</a>
            <a href="/auto-repairs/honda" className="button-link">Honda</a>
            <a href="/auto-repairs/audi" className="button-link">Audi</a>
            <a href="/auto-repairs/hyundai" className="button-link">Hyundai</a>
            <a href="/auto-repairs/mazda" className="button-link">Mazda</a>
            <a href="/auto-repairs/ford" className="button-link">Ford</a>
            <a href="/auto-repairs/bmw" className="button-link">BMW</a>
          </div>
        </div>
      </div>
      <div className="row justify-content-left">
        <div className="col-12">
          <a href="/auto-repairs" className="link">
            All specialist repairers
            <img src={DoubleRightIcon} alt="All specialist repairers"/>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Coverage;
